import { ChangeDetectionStrategy, Component } from '@angular/core';
// import { FormControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { ObjectExtraInfoService } from '../../object-wrapper/object-extra-info/object-extra-info.service';
import { NformNonuserService } from '../../nform-nonuser.service';

@Component({
	selector: 'nform-object-ratingimage',
	templateUrl: './ratingimage.component.html',
	styleUrls: ['./ratingimage.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NFormRatingimageComponent
	extends FieldType<NFormObjectFormlyFieldConfig>{

	static nFormObjectName = 'nform-ratingimage';

	eNFormRatingimageValues = NFormRatingimageValues;
	// _formControl: UntypedFormControl = this.formControl as FormControl<any>;

	tabControl = this.nformNonuserService.tabsControl;

	constructor(
		private objectExtraInfoService: ObjectExtraInfoService,
		protected nformNonuserService: NformNonuserService
	) {
		super();
	 }

	setValue(val: string){
		if(this.field.props?.readonly){
			return;
		}
		this.formControl.setValue(val);
		this.formControl.markAllAsTouched();
		this.objectExtraInfoService.checkFormNC(val, this.field);
	}
}

export enum NFormRatingimageValues {
	green = 'GREEN',
	yellow = 'YELLOW',
	red = 'RED',
	na = 'NA'
}
