/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { environment } from 'src/environments/environment';

let ApiUrls: any;

let hostPortalWeb: string;
let basePathPortal: string;
let basePathDashboards: string;
let basePathPreferences: string;

function setHosts(){
	hostPortalWeb = environment.portalWeb;
	basePathPortal = `${environment.api.host}/portal`;
	basePathDashboards = `${environment.api.host}/dashboards`;
	basePathPreferences = `${environment.api.host}/preferences`;
}

function setApiURLS(){

	setHosts();

	const urls = {

		portal: {

			// auth
			auth: `${basePathPortal}/auth`,
			authToken: `${basePathPortal}/auth/token`,
			authLogout: `${basePathPortal}/auth/logout`,
			status: `${basePathPortal}/status`,
			authTokenQRcode: `${basePathPortal}/auth/token/qrcode`,
			authRecoveryPass: `${basePathPortal}/auth/recovery-pass`,

			// translates
			translates: `${basePathPortal}/translates`,

			// resources
			profitsCenter: `${basePathPortal}/profits-center`,
			tags: `${basePathPortal}/tags`,
			sites: `${basePathPortal}/sites`,
			products: `${basePathPortal}/products`,

			// bi view
			dashboardsMetrics: `${basePathPortal}/dashboards/metrics`,
			dashboardsProfitsCenter: `${basePathPortal}/dashboards/profits-center`,
			dashboardsProfitsCenterGroupby: `${basePathPortal}/dashboards/profits-center/group-by/:groupbyparam`,
			dashboardsTags: `${basePathPortal}/dashboards/tags`,
			dashboardsSites: `${basePathPortal}/dashboards/sites`,
			dashboardsProducts: `${basePathPortal}/dashboards/products`,

			// bi metrics charts
			dashboardsMetricsChart: `${basePathPortal}/dashboards/metrics-chart/:metric/:timeunit`,

			// bi filters
			dashboardsFiltersProducts: `${basePathPortal}/dashboards-filters/products`,
			dashboardsFiltersProfitsCenter: `${basePathPortal}/dashboards-filters/profits-center`,
			dashboardsFiltersTags: `${basePathPortal}/dashboards-filters/tags`,
			dashboardsFiltersSites: `${basePathPortal}/dashboards-filters/sites`,
			dashboardsFiltersEconomicGroups: `${basePathPortal}/dashboards-filters/economic-group`,

			// nform-nonuser
			nformNonuser: `${basePathPortal}/nform-nonuser`,

			// management-dashboards
			operationalDashboards: `${basePathPortal}/operational-dashboards`,
			operationalDashboardsGroups: `${basePathPortal}/operational-dashboards/groups`,

			// assets
			assets: `${basePathPortal}/assets`,
			assetsSearch: `${basePathPortal}/assets/search/:keyword`,
			assetsDefaultQrCode: `${basePathPortal}/assets/default-qrcode`,
			assetsCheckQrCodeParameters: `${basePathPortal}/assets/check-qrcode-parameters`,

			// tickets
			tickets: `${basePathPortal}/tickets`,
			ticketsNotKanban: `${basePathPortal}/tickets/not-kanban`,
			ticketsJustify: `${basePathPortal}/tickets/justify`,

			// service order planning
			serviceOrderPlanning: `${basePathPortal}/service-order-planning`,
			_portal_serviceOrderPlanning: `${hostPortalWeb}/ws/webapp/service-order-planning.ws`,
			serviceOrderPlanningWorkgroups: `${basePathPortal}/service-order-planning/workgroups`,
			serviceOrderPlanningSites: `${basePathPortal}/service-order-planning/sites`,
			serviceOrderPlanningProducts: `${basePathPortal}/service-order-planning/products`,
			serviceOrderPlanningRegions: `${basePathPortal}/service-order-planning/regions`,
			serviceOrderPlanningZones: `${basePathPortal}/service-order-planning/zones`,
			serviceOrderPlanningContracts: `${basePathPortal}/service-order-planning/contracts`,
			serviceOrderPlanningLocals: `${basePathPortal}/service-order-planning/locals`,
			serviceOrderPlanningStructures: `${basePathPortal}/service-order-planning/structures`,
			serviceOrderPlanningSerialBrands: `${basePathPortal}/service-order-planning/serial-brands`,
			serviceOrderPlanningSerialModels: `${basePathPortal}/service-order-planning/serial-models`,
			serviceOrderPlanningUsers: `${basePathPortal}/service-order-planning/users`,
			serviceOrderPlanningUsersProfile: `${basePathPortal}/service-order-planning/users-profile`,
			serviceOrderPlanningUserProfileStandard: `${basePathPortal}/service-order-planning/user-profile-standard`,
			serviceOrderPlanningSerialBlock: `${basePathPortal}/service-order-planning/serial-block`,
			serviceOrderPlanningSerialLogMovements: `${basePathPortal}/service-order-planning/serial-log-movements`,
			serviceOrderPlanningSerialLogFixes: `${basePathPortal}/service-order-planning/serial-log-fixes`,
			serviceOrderPlanningSerialLogExec: `${basePathPortal}/service-order-planning/serial-log-executions`,
			serviceOrderPlanningSerialItemExecutions: `${basePathPortal}/service-order-planning/serial-items-executions`,
			serviceOrderPlanningSerialItems: `${basePathPortal}/service-order-planning/serial-items`,
			// serviceOrderPlanningSerialClass: `${basePathPortal}/service-order-planning/serial-class`,
			serviceOrderPlanningSerialMove: `${basePathPortal}/service-order-planning/serial-move`,
			serviceOrderPlanningSerialTicket: `${basePathPortal}/service-order-planning/tickets`,
			serviceOrderPlanningSerialTicketsOpened: `${basePathPortal}/service-order-planning/tickets/opened`,
			serviceOrderPlanningSerialTicketKanban: `${basePathPortal}/service-order-planning/tickets/kanban`,
			serviceOrderPlanningSerialTicketWorkgroups: `${basePathPortal}/service-order-planning/tickets/workgroups`,
			serviceOrderPlanningSerialTicketOperations: `${basePathPortal}/service-order-planning/tickets/operations`,
			serviceOrderPlanningSerialTicketPipelines: `${basePathPortal}/service-order-planning/tickets/pipelines`,
			serviceOrderPlanningSerialTicketFormOS: `${basePathPortal}/service-order-planning/tickets/form-os`,
			serviceOrderPlanningSerialTicketFormOSItem: `${basePathPortal}/service-order-planning/tickets/form-os/:customFormType/:customFormCode/:customFormVersion/:customFormData`,
			serviceOrderPlanningSerialTicketFormOSURLPreviewHTML: `${basePathPortal}/service-order-planning/tickets/form-os/:customFormType/:customFormCode/:customFormVersion/:customFormData/url-html-preview`,
			serviceOrderPlanningSerialTicketFormOSKanban: `${basePathPortal}/service-order-planning/tickets/form-os-kanban`,
			serviceOrderPlanningSerialTicketFormOSTypes: `${basePathPortal}/service-order-planning/tickets/form-os-types`,
			serviceOrderPlanningSerialTicketTypes: `${basePathPortal}/service-order-planning/tickets/types`,
			serviceOrderPlanningSerialTicketProcesses: `${basePathPortal}/service-order-planning/tickets/processes`,
			serviceOrderPlanningTicketUsersRating: `${basePathPortal}/service-order-planning/tickets/users-rating`,
			serviceOrderPlanningTicketClass: `${basePathPortal}/service-order-planning/tickets/classes`,
			serviceOrderPlanningTicketsCtrlFile: `${basePathPortal}/service-order-planning/tickets/ctrl-file`,
			serviceOrderPlanningSerialUsage: `${basePathPortal}/service-order-planning/serial-usage`,
			serviceOrderPlanningSerialAvailabilityByMonth: `${basePathPortal}/service-order-planning/serial-availability-by-month`,
			serviceOrderPlanningSerialAvailability: `${basePathPortal}/service-order-planning/serial-availability`,
			serviceOrderPlanningSiteAvailability: `${basePathPortal}/service-order-planning/site-availability`,

			serviceOrderPlanningSerialMeasureAverage: `${basePathPortal}/service-order-planning/serial-measure/average`,
			serviceOrderPlanningSerialMeasureMonthly: `${basePathPortal}/service-order-planning/serial-measure/monthly`,

			serviceOrderPlanningSerialSuggestedPlanned: `${basePathPortal}/service-order-planning/serial-suggested-planned`,

			serviceOrderPlanningTicketNC: `${basePathPortal}/tickets/nc`,

			// Field Service
			fieldServiceTripMonitor: `${basePathPortal}/field-service/trip-monitor`,
			fieldServicePastCalendar: `${basePathPortal}/field-service/trip-monitor/past-calendar`,
			fieldServiceMapMonitor: `${basePathPortal}/field-service/trip-monitor/map`,
			fieldServiceTripMonitorOverdueTickets: `${basePathPortal}/field-service/trip-monitor/overdue-tickets`,

			// service order
			serviceOrder: `${basePathPortal}/service-order`,
			serviceOrderCalendarSites: `${basePathPortal}/service-order/calendar/sites`,
			serviceOrderCalendar: `${basePathPortal}/service-order/calendar`,
			serviceOrderCalendarWeek: `${basePathPortal}/service-order/calendar/week`,
			serviceOrderCalendarDayHours: `${basePathPortal}/service-order/calendar/day-hours`,
			serviceOrderItem: `${basePathPortal}/service-order/:soPrefix/:soCode`,
			serviceOrderPDFItem: `${basePathPortal.replace(/namoa\.io/,'namoadigital.com')}/service-order/pdf/:soPrefix/:soCode/:viewType`,
			serviceOrderSerialSearch: `${basePathPortal}/service-order/serial-search`,
			serviceOrderSerialSearchPasteTxt: `${basePathPortal}/service-order/serial-search/paste-txt`,
			serviceOrderOperations: `${basePathPortal}/service-order/operations`,
			serviceOrderFavorites: `${basePathPortal}/service-order/favorites`,
			serviceOrderServiceList: `${basePathPortal}/service-order/services`,
			serviceOrderServicePartner: `${basePathPortal}/service-order/services-partners`,
			serviceOrderServiceExecute: `${basePathPortal}/service-order/services-execute`,
			serviceOrderSerialItem: `${basePathPortal}/service-order/serial/:productCode/:serialCode`,
			serviceOrderServiceBrand: `${basePathPortal}/service-order/serial/brand`,
			serviceOrderServiceBrandModel: `${basePathPortal}/service-order/serial/model`,
			serviceOrderServiceBrandColor: `${basePathPortal}/service-order/serial/color`,
			serviceOrderServiceCategory: `${basePathPortal}/service-order/serial/category-price`,
			serviceOrderServiceSizeZoneLocal: `${basePathPortal}/service-order/site-zone-local`,
			serviceOrderServiceSizeZoneExec: `${basePathPortal}/service-order/site-zone-exec`,
			serviceOrderServicePipeline: `${basePathPortal}/service-order/serial/pipeline`,
			serviceOrderServicePriority: `${basePathPortal}/service-order/serial/priority`,
			serviceOrderEDIProcess: `${basePathPortal}/service-order/edi-process`,

			// Tech User Planning
			techUserPlanning: `${basePathPortal}/tech-user-planning`,
			techUserPlanningCommit: `${basePathPortal}/tech-user-planning/commit`,
			techUserPlanningSearchTickets: `${basePathPortal}/tech-user-planning/search-tickets`,
			techUserPlanningPreview: `${basePathPortal}/tech-user-planning/preview`,
			techUserPlanningRemoveTemp: `${basePathPortal}/tech-user-planning/remove-temp`,
			techUserPlanningMap: `${basePathPortal}/tech-user-planning/map`,

			// Utils
			utilsAddressCountries: `${basePathPortal}/utils/address-countries`,
			utilsAddressZipcode: `${basePathPortal}/utils/address-zipcode`,
			utilsAddressLatLong: `${basePathPortal}/utils/address-latlong`,
			utilsUserPreferences: `${basePathPortal}/utils/user-preferences`,

			// aws upload file
			uploadFile: `${hostPortalWeb}/inc/ws/aws_file_webapp.ws`,

			//master-data
			masterDataClient: `${basePathPortal}/master-data/clients`,

			// white label
			whiteLabelLogos: `${hostPortalWeb}/inc/ws/subdomain_image.ws`,

		},

		dashboards: {
			status: `${basePathDashboards}/status`,
		},

		preferences: {
			status: `${basePathPreferences}/status`,
		}

	};

	mergeApiUrls(urls);

};

function mergeApiUrls(urls: any){
	if(!ApiUrls){
		ApiUrls = urls;
	}else{
		Object.assign(ApiUrls, urls);
	}
}

setApiURLS();

export { ApiUrls, setApiURLS };
