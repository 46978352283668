<div
	layout="column"
	[ngClass]="{
		'ng-invalid': formControl.invalid,
		'ng-valid': formControl.valid
	}"
>
	<p class="mat-body">
		<span *ngIf="field.data.enumarated">
			{{tabControl.tabs[tabControl.current-1].tabNumber}}.{{field.data.enumarated}}.
		</span>

		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<div
		layout-xs="column"
		layout-sm="column"
		layout-gt-sm="row"
	>
		<mat-button-toggle-group [formControl]="_formControl">
			<mat-button-toggle
				(click)="checkFormNC(1)"
				flex
				value="1"
			>
				{{'Components.NForm.CHECKBOX.yes' | transloco}}
			</mat-button-toggle>

			<mat-button-toggle
				(click)="checkFormNC(0)"
				flex
				value="0"
			>
				{{'Components.NForm.CHECKBOX.no' | transloco}}
			</mat-button-toggle>

			<mat-button-toggle
				*ngIf="field.data.custom_form_data_content?.others === '1'"
				(click)="checkFormNC(-999)"
				flex
				value="-999"
			>
				{{'Components.NForm.CHECKBOX.others' | transloco}}
			</mat-button-toggle>

			<mat-button-toggle
				*ngIf="!field.data.required"
				(click)="checkFormNC(-1)"
				flex
				value="-1"
			>
				{{'Components.NForm.CHECKBOX.na' | transloco}}
			</mat-button-toggle>
		</mat-button-toggle-group>
	</div>


	<div
		class="mat-mdc-form-field-subscript-wrapper"
		theme-palette-hue-fg="warn"
	>
		<mat-error>
			<formly-validation-message [field]="field"></formly-validation-message>
		</mat-error>
	</div>

	<div
		*ngIf="to.required"
		class="mat-mdc-form-field-subscript-wrapper fixed-required"
		theme-palette-hue-fg="warn"
	>
		{{'Form.ValidationMessage.required' | transloco}}
	</div>

</div>
