import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';
import { DatePipe } from '@angular/common';
import { NformNonuserService } from '../../nform-nonuser.service';

@Component({
	selector: 'nform-object-hour',
	templateUrl: './hour.component.html',
	styleUrls: ['./hour.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NFormHourComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit
{

	static nFormObjectName = 'nform-hour';

	_formControl: UntypedFormControl;
	pickerFormat = 24;

	datePlaceHolder: Date;

	tabControl = this.nformNonuserService.tabsControl;

	constructor(
		private datePipe: DatePipe,
		protected nformNonuserService: NformNonuserService
	){
		super();
	}

	ngOnInit(){
		this._formControl = this.formControl as FormControl<any>;
		if(this._formControl.value){
			const time = this._formControl.value.split(':');
			if(!this.datePlaceHolder){
				this.datePlaceHolder = new Date();
			}
			this.datePlaceHolder.setHours(time[0]);
			this.datePlaceHolder.setMinutes(time[1]);
		}
	}

	onTimePickerChange(date: Date){
		this._formControl.setValue(
			this.datePipe.transform(date, 'HH:mm')
		);
	}
}
