import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { isRequestURLAppAPI } from './request.helpers';
import { TranslocoService } from '@jsverse/transloco';
import { CustomHttpRequest } from './custom-erro-response';

@Injectable({
	providedIn: 'root'
})

export class DefaultTokenHeaderService implements HttpInterceptor {

	constructor(
		private authService: AuthService,
		private translocoService: TranslocoService
	) {}

	intercept(
		request: CustomHttpRequest,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		if(isRequestURLAppAPI(request.url)){

			const token = this.authService.getToken();
			const language = this.translocoService.getActiveLang();
			const headers = {};

			if (token){
				headers['access-token'] = token;
			}

			if(language){
				headers['app-language'] = language;
			}

			// workaround to extra request information such as friendly messages
			const extras = JSON.parse(request.headers.get('extras') || '{}');

			request = this.requestClone(request, headers);

			request.extras = extras;

			console.log('request:', DefaultTokenHeaderService.name, request, token);

		}

	 return next.handle(request);

	}

	private requestClone(
		request: CustomHttpRequest,
		headers: any
	): CustomHttpRequest{

		const regexpPortalWeb = new RegExp(environment.portalWeb);

		if(regexpPortalWeb.test(request.url) && !/namoadigital.com\/ws\/webapp/.test(request.url)){
			return request.clone({
				headers: request.headers.delete('extras'),
			}) as CustomHttpRequest;
		}else{
			return request.clone({
				headers: request.headers.delete('extras'),
				setHeaders: headers
			}) as CustomHttpRequest;
		}


	}

}
